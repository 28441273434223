import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import ContactForm from "../components/Contactformulier";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const BlogPageTemplate = ({ post }) => {
  return (
    <>
      <HTMLContent
        className="prose prose-lg container mx-auto max-w-none  max-w-5xl"
        content={post.html}
      />
    </>
  );
};

const BlogPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <>
      <SEO
        description={post.frontmatter.seo.meta_description}
        pathname={post.frontmatter.path}
        title={post.frontmatter.seo.meta_title}
      />

      <Layout
        heroBackgroundImage={post.frontmatter.hero_background_image}
        title={post.frontmatter.title}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl  ">
            <BlogPageTemplate content={post.html} post={post} />
          </section>
          <ContactForm />
        </main>
      </Layout>
    </>
  );
};

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPage;

export const BlogPageQuery = graphql`
  query BlogPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        hero_background_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        seo {
          meta_title
          meta_description
        }
        subtitle
        show_contact_form
      }
    }
  }
`;
